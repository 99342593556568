import { login, me, twoFactorAuthChallenge } from 'api/auth'
import LoaderButton from 'components/LoaderButton'
import Logo from 'components/logo/Logo'
import { AuthContext } from 'contexts/AuthContext'
import React, { useContext, useState } from 'react'
import { Button } from 'react-bootstrap'
import Alert from 'react-bootstrap/Alert'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import { Link } from 'react-router-dom'
import styles from './Login.module.scss'
import SmallHeader from 'components/headers/SmallHeader'

function Login(props) {
  const { setAuthenticated, setUser } = useContext(AuthContext)
  const [twoFactor, setTwoFactor] = useState(false)

  const handleTwoFactor = () => setTwoFactor(true)

  const handleLogin = async () => {
    setUser(await me())
    setAuthenticated(true)
  }

  return (
    <Container fluid className='h-100'>
      <Row className={styles.row}>
        <Col lg={5} className={styles.logoContainer}>
          <Logo large />
        </Col>

        <Col lg={7} className={styles.formContainer}>
          {twoFactor ? (
            <TwoFactorForm onLogin={handleLogin} />
          ) : (
            <LoginForm onLogin={handleLogin} onTwoFactor={handleTwoFactor} />
          )}
        </Col>
      </Row>
    </Container>
  )
}

function TwoFactorForm({ onLogin }) {
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState('')
  const [useRecoveryCode, setUseRecoveryCode] = useState(false)
  const [messageSeverity, setMessageSeverity] = useState('info')

  const [code, setCode] = useState()

  const handleChange = event => {
    setCode(event.target.value)
  }

  const handleSubmit = async event => {
    event.preventDefault()

    setLoading(true)
    const result = await twoFactorAuthChallenge(
      useRecoveryCode ? { recovery_code: code } : { code: code }
    )
    if (result) {
      await onLogin()
      setLoading(false)
    } else {
      setMessage('Invalid code')
      setMessageSeverity('danger')
      setLoading(false)
    }
  }

  return (
    <Form className={styles.form} onSubmit={handleSubmit}>
      {useRecoveryCode && <SmallHeader>Recovery Code Login</SmallHeader>}

      <Form.Group className='mb-4'>
        <Form.Group>
          <Form.Control
            name='code'
            placeholder={useRecoveryCode ? 'Enter recovery code' : 'Enter 2FA Code'}
            onChange={handleChange}
          />
        </Form.Group>
      </Form.Group>

      <LoaderButton
        label='Login'
        className='mb-4'
        loading={loading}
        variant='primary'
        size='lg'
        type='submit'
        block
      />

      {!useRecoveryCode && (
        <Button variant='default' block onClick={() => setUseRecoveryCode(true)}>
          I'd like to use a recovery code
        </Button>
      )}

      {message && (
        <Alert className='mt-4' variant={messageSeverity}>
          {message}
        </Alert>
      )}
    </Form>
  )
}

function LoginForm({ onLogin, onTwoFactor }) {
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState('')
  const [messageSeverity, setMessageSeverity] = useState('info')

  const validateForm = () => {
    let valid = true
    let newValues = values

    if (values.email.value.length <= 0) {
      newValues = { ...newValues, email: { value: values.email.value, invalid: true } }
      valid = false
    }

    if (values.password.value.length <= 0) {
      newValues = {
        ...newValues,
        password: { value: values.password.value, invalid: true },
      }
      valid = false
    }

    setValues(newValues)
    return valid
  }

  const [values, setValues] = useState({
    email: { value: '', invalid: false },
    password: { value: '', invalid: false },
    remember: 1,
  })

  const handleChange = event => {
    const { name, value } = event.target
    setValues({ ...values, [name]: { value: value, invalid: false } })
  }

  const handleSubmit = async event => {
    event.preventDefault()

    if (validateForm()) {
      setLoading(true)
      const result = await login(values.email.value, values.password.value)
      if (result.success) {
        if (result.two_factor) {
          onTwoFactor()
          setLoading(false)
        } else {
          await onLogin()
          setLoading(false)
        }
      } else {
        setMessage('Invalid username or password')
        setMessageSeverity('danger')
        setLoading(false)
      }
    }
  }

  return (
    <Form className={styles.form} onSubmit={handleSubmit}>
      <Form.Group className='mb-4'>
        <Form.Group>
          <Form.Control
            isInvalid={values.email.invalid}
            type='email'
            name='email'
            placeholder='Email address'
            onChange={handleChange}
          />
        </Form.Group>

        <Form.Group>
          <Form.Control
            isInvalid={values.password.invalid}
            type='password'
            name='password'
            placeholder='Password'
            onChange={handleChange}
          />
        </Form.Group>
      </Form.Group>
      <LoaderButton
        label='Login'
        className='mb-4'
        loading={loading}
        variant='primary'
        size='lg'
        type='submit'
        block
      />

      <Link to='/password/reset'>Forgot password</Link>

      {message && (
        <Alert className='mt-4' variant={messageSeverity}>
          {message}
        </Alert>
      )}
    </Form>
  )
}

export default Login
