import Tooltip from 'components/Tooltip'
import React from 'react'
import { QuestionCircleFill } from 'react-bootstrap-icons'
import styles from './Help.module.scss'

function DisplayLinkTypeHelp(props) {
  return (
    <Tooltip
      label={
        <>
          <div className='mb-3'>
            <strong>Download</strong>
            <br />
            Force the browser to download all media files before playing. This will reduce
            customer and 5G Live bandwidth and allow offline playback.
          </div>
          <div>
            <strong>Browser</strong>
            <br />
            The browser will handle the downloading of media. It may or may not cache
            items.
          </div>
        </>
      }>
      <span className={styles.help}>
        <QuestionCircleFill />
      </span>
    </Tooltip>
  )
}

export default DisplayLinkTypeHelp
