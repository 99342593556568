import LargeHeader from 'components/headers/LargeHeader'
import WithCreateContext, { CreateContext } from 'contexts/CreateContext'
import MediaContext from 'contexts/MediaContext'
import React, { useContext, useEffect, useState } from 'react'
import { Button, Container } from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
import { useParams } from 'react-router-dom'
import CreateContent from './CreateContent'
import IframeContent from './IframeContent'

const renderType = type => {
  switch (type) {
    case 'iframe':
      return <IframeContent />
    default:
      return <CreateContent />
  }
}

function Create(props) {
  const { playlistId, slideId } = useParams()

  return (
    <WithCreateContext playlistId={playlistId} slideId={slideId} history={props.history}>
      <MediaContext>
        <CreateContainer />
      </MediaContext>
    </WithCreateContext>
  )
}

function CreateContainer(props) {
  const { state, slideId } = useContext(CreateContext)
  const [type, setType] = useState('media')
  const [typeConfirmed, setTypeConfirmed] = useState(slideId ? true : false)

  useEffect(() => {
    setType(slideId ? state.type : '')
    setTypeConfirmed(slideId ? true : false)
  }, [slideId, state.type])

  return (
    <React.Fragment>
      {typeConfirmed ? (
        <Container fluid style={{ maxWidth: '1600px', margin: 'auto' }}>
          {renderType(type)}
        </Container>
      ) : (
        <Container>
          <LargeHeader>Slide Type</LargeHeader>

          <div className='mb-2'>What type of slide would you like to create?</div>
          <div className='mb-1'>
            <strong>Image or video: </strong> Select images or videos from your library
            and custom layers using the 5G Live editor.
          </div>
          <div className='mb-2'>
            <strong>Embedded website: </strong> Enter the URL of a website to display for
            the duration of the slide.
          </div>

          <Form.Group>
            <Form.Control
              as='select'
              placeholder='Playlist to display'
              name='playlist_id'
              value={type}
              onChange={e => setType(e.target.value)}>
              <option disabled value=''>
                Please select...
              </option>
              <option value='media'>Image or video</option>
              <option value='iframe'>Embedded website</option>
            </Form.Control>
          </Form.Group>

          <Button className='mb-3 mr-2' onClick={() => setTypeConfirmed(true)}>
            Next
          </Button>
        </Container>
      )}
    </React.Fragment>
  )
}

export default Create
