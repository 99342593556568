import { list as listSchedules } from 'api/playlist-schedules'
import { listAll as listPlaylists, update as updatePlaylist } from 'api/playlists'
import { deleteScreen, list, setFolder, update } from 'api/screens'
import CreateADigitalDisplay from 'components/CreateADigitalDisplay'
import DeleteModal from 'components/DeleteModal'
import Loader from 'components/Loader'
import PreviewModal from 'components/PreviewModal'
import RefreshModal from 'components/RefreshModal'
import Tooltip from 'components/Tooltip'
import UsageBanner from 'components/UsageBanner'
import CreateFolder from 'components/create-folder/CreateFolder'
import DeleteFolder from 'components/delete-folder/DeleteFolder'
import LargeHeader from 'components/headers/LargeHeader'
import ListFolders from 'components/list-folders/ListFolders'
import Panel from 'components/panel/Panel'
import Toolbar, { ToolbarGroup } from 'components/toolbar/Toolbar'
import UpdateFolder from 'components/update-folder/UpdateFolder'
import { useEffect, useState } from 'react'
import { Badge, Button, Dropdown, DropdownButton } from 'react-bootstrap'
import {
  ArrowLeftCircle,
  ArrowRepeat,
  CheckCircleFill,
  CircleFill,
  Display,
  Folder,
  House,
  Pencil,
  Trash,
  XCircleFill,
} from 'react-bootstrap-icons'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import Spinner from 'react-bootstrap/Spinner'
import Table from 'react-bootstrap/Table'
import { Link, useParams } from 'react-router-dom'
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
} from 'react-share'
import styles from './Screens.module.scss'

function Screens(props) {
  const [loading, setLoading] = useState(true)
  const [updating, setUpdating] = useState(false)
  const [screens, setScreens] = useState({})
  const [displayUrl, setDisplayUrl] = useState('')
  const [previewUrl, setPreviewUrl] = useState('')
  const [previewOrientation, setPreviewOrientation] = useState('landscape')
  const [previewAspectRatio, setPreviewAspectRatio] = useState('16:9')
  const [previewOpen, setPreviewOpen] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [deleteOpen, setDeleteOpen] = useState(false)
  const [deleteId, setDeleteId] = useState('')
  const [deleteIndex, setDeleteIndex] = useState('')
  const [deleteError, setDeleteError] = useState('')
  const [playlists, setPlaylists] = useState()
  const [schedules, setSchedules] = useState()

  const { folderId } = useParams()

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)

      const [_playlists, _schedules, _screens] = await Promise.all([
        listPlaylists(),
        listSchedules(),
        list(15, 1, folderId),
      ])

      setPlaylists(_playlists.data)
      setSchedules(_schedules.data)
      setScreens(_screens)
      setLoading(false)
    }

    fetchData()
  }, [folderId])

  const handleClickPreview = (url, previewUrl, orientation, aspectRatio) => {
    setDisplayUrl(url)
    setPreviewUrl(previewUrl)
    setPreviewOrientation(orientation)
    setPreviewAspectRatio(aspectRatio)
    setPreviewOpen(true)
  }

  const handleOpenDeleteDialog = (id, index) => {
    setDeleteId(id)
    setDeleteIndex(index)
    setDeleteOpen(true)
  }

  const handleCloseDeleteDialog = () => {
    setDeleteOpen(false)
  }

  const handleDelete = async (id, index) => {
    setDeleteError(false)
    setDeleting(true)
    const success = await deleteScreen(id)

    if (success) {
      const res = await list()
      setScreens(res)
      setDeleteOpen(false)
    } else {
      setDeleteError(true)
    }

    setDeleting(false)
  }

  const reloadData = async () => {
    const res = await list(15, 1, folderId)
    setScreens(res)
  }

  const handleChangeFolder = (screenId, folderId) => {
    const newScreens = { ...screens }
    const newData = screens.data.filter(x => x.id !== screenId)
    newScreens.data = newData
    setScreens(newScreens)
    setFolder({ screen_id: screenId, folder_id: folderId })
  }

  const handleChangeDisplayItem = async (screenId, itemId) => {
    setUpdating(true)
    await update(screenId, {
      display_item_id: itemId,
    })
    const screens = await list(15, 1, folderId)
    setScreens(screens)
    setUpdating(false)
  }

  if (loading) {
    return <Loader />
  }

  if (screens.meta.playlist_count === 0) {
    return (
      <div style={{ maxWidth: '400px', margin: 'auto' }}>
        <CreateADigitalDisplay />
      </div>
    )
  }

  return (
    <Container>
      <LargeHeader>Displays</LargeHeader>

      <UsageBanner
        used={screens.meta.usage.used}
        limit={screens.meta.usage.limit}
        exceededLimit={screens.meta.usage.used >= screens.meta.usage.limit}>
        {' '}
        displays created
      </UsageBanner>

      <ScreensToolbar
        state={screens}
        parentFolderId={
          screens.meta.folder.id !== '' ? screens.meta.parent_folder.id : undefined
        }
        reloadData={reloadData}
      />

      <ListFolders
        folders={screens.meta.child_folders.map(x => {
          x.otherProps = { as: Link, to: `/displays/${x.id}` }
          return x
        })}
      />

      <Panel>
        {screens.data.length === 0 ? (
          <div>No displays created yet.</div>
        ) : (
          <Table responsive='sm'>
            <thead>
              <tr>
                <th>Name</th>
                {screens.meta.show_status && <th className='text-center'>Status</th>}
                <th>Schedule/Playlist</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {screens.data.map((x, index) => (
                <tr key={x.id}>
                  <td>{x.name}</td>
                  {screens.meta.show_status && (
                    <td className='text-center'>
                      {x.status === 'not_in_use' ? (
                        <Tooltip label='Not in use'>
                          <CircleFill color='#6c757d' />
                        </Tooltip>
                      ) : x.status === 'online' ? (
                        <Tooltip label='Online'>
                          <CheckCircleFill color='#63d75c' />
                        </Tooltip>
                      ) : (
                        <Tooltip label='Offline'>
                          <XCircleFill color='#dc3545' />
                        </Tooltip>
                      )}
                    </td>
                  )}
                  <td className='pt-2'>
                    <Form.Control
                      as='select'
                      placeholder='Schedule or playlist to display'
                      name='display_item_id'
                      value={
                        x.schedule?.id
                          ? 's|' + x.schedule.id
                          : x.playlist?.id
                          ? 'p|' + x.playlist.id
                          : null
                      }
                      onChange={e => {
                        handleChangeDisplayItem(x.id, e.target.value)
                      }}>
                      <option value='' disabled>
                        Please select
                      </option>

                      {schedules.length > 0 && (
                        <>
                          <option value='' disabled style={{ fontSize: '1.4rem' }}>
                            Schedules
                          </option>
                          {schedules.map(x => (
                            <option key={x.id} value={`s|${x.id}`}>
                              {x.name}
                            </option>
                          ))}
                        </>
                      )}

                      {playlists.length > 0 && (
                        <>
                          <option value='' disabled style={{ fontSize: '1.4rem' }}>
                            Playlists
                          </option>
                          {playlists.map(x => (
                            <option key={x.id} value={`p|${x.id}`}>
                              {x.name}
                            </option>
                          ))}
                        </>
                      )}
                    </Form.Control>
                  </td>
                  <td align='right' style={{ minWidth: '135px' }}>
                    <Tooltip label='Change folder'>
                      <DropdownButton
                        size='xs'
                        className={`${styles.dropdown} mr-1 d-inline-block`}
                        name='display-images-for'
                        title={<Folder />}>
                        {screens.meta.folders.map(folder => (
                          <Dropdown.Item
                            key={`${x.id}-${folder.id}`}
                            onClick={() => handleChangeFolder(x.id, folder.id)}>
                            {folder.name}
                          </Dropdown.Item>
                        ))}
                      </DropdownButton>
                    </Tooltip>
                    <Tooltip label='Edit Display'>
                      <Button
                        className='mr-1 px-2'
                        variant='primary'
                        size='sm'
                        as={Link}
                        to={`/displays/update/${x.id}`}>
                        <Pencil />
                      </Button>
                    </Tooltip>
                    <RefreshDisplayButton id={x.playlist.id} />
                    <Tooltip label='View Display URL'>
                      <Button
                        className='mr-1 px-2'
                        variant='primary'
                        size='sm'
                        onClick={() =>
                          handleClickPreview(
                            x.url,
                            x.preview_url,
                            x.playlist.orientation,
                            x.playlist.aspect_ratio
                          )
                        }>
                        <Display />
                      </Button>
                    </Tooltip>
                    <Tooltip label='Delete Display'>
                      <Button
                        variant='danger'
                        className='px-2'
                        size='sm'
                        onClick={() => handleOpenDeleteDialog(x.id, index)}>
                        <Trash />
                      </Button>
                    </Tooltip>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Panel>

      <PreviewModal
        open={previewOpen}
        onHide={() => {
          setPreviewOpen(false)
        }}
        orientation={previewOrientation}
        aspectRatio={previewAspectRatio}
        url={previewUrl}>
        <div className='mb-2'>You can use the below URL for your displays.</div>
        <div className='mb-3'>
          <strong>{displayUrl}</strong>
        </div>
        <div className='mb-4'>
          <LinkedinShareButton url={previewUrl} className='mr-1'>
            <LinkedinIcon size={32} round={true} />
          </LinkedinShareButton>
          <FacebookShareButton url={previewUrl} className='mr-1'>
            <FacebookIcon size={32} round={true} />
          </FacebookShareButton>
          <TwitterShareButton url={previewUrl} className='mr-1'>
            <TwitterIcon size={32} round={true} />
          </TwitterShareButton>
        </div>
      </PreviewModal>

      <DeleteModal
        show={deleteOpen}
        onHide={handleCloseDeleteDialog}
        deleting={deleting}
        onDelete={() => handleDelete(deleteId, deleteIndex)}
        errorMessage={deleteError}
      />
      {updating && (
        <div className='fixed-bottom mb-4 ml-4'>
          <Spinner animation='border' />
        </div>
      )}
    </Container>
  )
}

function RefreshDisplayButton({ id }) {
  const [refreshOpen, setRefreshOpen] = useState(false)
  const [refreshing, setRefreshing] = useState(false)
  const [refreshed, setRefreshed] = useState(false)

  const handleClickRefresh = () => {
    setRefreshOpen(true)
  }

  const handleRefresh = () => {
    setRefreshing(true)
    updatePlaylist(id, {})
    setRefreshed(true)
    setRefreshing(false)
    setRefreshOpen(false)
  }

  return (
    <>
      <Tooltip
        label={
          refreshed
            ? 'Refresh signal sent to display, allow up to 10 minutes for displays to reload'
            : 'Refresh Display'
        }>
        <Button
          disabled={refreshed}
          className='mr-1 px-2'
          variant='primary'
          size='sm'
          onClick={handleClickRefresh}>
          <ArrowRepeat />
        </Button>
      </Tooltip>
      <RefreshModal
        open={refreshOpen}
        onHide={() => {
          setRefreshOpen(false)
        }}
        loading={refreshing}
        onSubmit={handleRefresh}
        id={id}
      />
    </>
  )
}

function ScreensToolbar({ state, parentFolderId, reloadData }) {
  const { folderId } = useParams()

  return (
    <Toolbar>
      <ToolbarGroup>
        {state.meta.usage.used < state.meta.usage.limit && (
          <div className='mr-1 mb-1'>
            <Tooltip label='Create a new display'>
              <Button
                variant='success'
                as={Link}
                to={`/displays/create/${folderId ?? ''}`}>
                Create
              </Button>
            </Tooltip>
          </div>
        )}

        {parentFolderId !== undefined && (
          <>
            <Tooltip label='Media Home'>
              <Button
                as={Link}
                to='/displays'
                variant='primary'
                className='mr-1 px-2 mb-1'>
                <House />
              </Button>
            </Tooltip>

            <Tooltip label='Back'>
              <Button
                as={Link}
                to={`/displays/${parentFolderId}`}
                variant='primary'
                className='mr-1 px-2 mb-1'>
                <ArrowLeftCircle />
              </Button>
            </Tooltip>
          </>
        )}
      </ToolbarGroup>

      <ToolbarGroup>
        {state.meta.folder.id !== '' && (
          <Badge variant='secondary' className='mr-1 px-2 d-flex align-items-center mb-1'>
            {state.meta.folder.name}
          </Badge>
        )}

        <CreateFolder
          type='SCREEN'
          parentFolderId={state.meta.folder.id}
          onSuccess={reloadData}
        />

        {state.meta.folder.id !== '' && (
          <>
            <UpdateFolder
              folderId={state.meta.folder.id}
              defaultName={state.meta.folder.name}
              onSuccess={reloadData}
            />

            <DeleteFolder
              folderId={state.meta.folder.id}
              name={state.meta.folder.name}
              onSuccess={reloadData}
            />
          </>
        )}
      </ToolbarGroup>
    </Toolbar>
  )
}

export default Screens
