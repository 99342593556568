import Panel from 'components/panel/Panel'
import React from 'react'
import { Box, Building, People, Tv } from 'react-bootstrap-icons'
import Container from 'react-bootstrap/Container'
import ActionRow from './ActionRow'
import styles from './Dashboard.module.scss'

function ResellerDashboard({ deviceStatusUrl }) {
  return (
    <Container>
      <div>
        <Panel>
          <ActionRow
            title='Manage Customers'
            icon={<Building className={styles.actionIcon} />}
            color='#bdeeba'
            to='/customers'>
            Manage and create customers to use the system.
          </ActionRow>
          <ActionRow
            title='Manage Packages'
            icon={<Box className={styles.actionIcon} />}
            color='#fffbc7'
            to='/packages'>
            Manage and create billing packages.
          </ActionRow>
          <ActionRow
            title='Manage Admin Users'
            icon={<People className={styles.actionIcon} />}
            color='#f4b9a2'
            to='/users'>
            Setup additional admin user to manage 5G Live.
          </ActionRow>
          <ActionRow
            title='Devices Dashboard'
            icon={<Tv className={styles.actionIcon} />}
            color='#e3f0f9'
            to={deviceStatusUrl}
            aLink>
            View device statuses for your customers.
          </ActionRow>
        </Panel>
      </div>
    </Container>
  )
}

export default ResellerDashboard
